import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import rccSafetyTable from "../../../assets/RCC-Adjuvant-564-safety-small-V6.svg"
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-564 - Adverse Reactions & Safety Data`,
    keywords: `rcc adverse reactions, adjuvant treatment, keytruda`,
    description: `Learn about selected immune-mediated adverse reactions to KEYTRUDA® (pembrolizumab) in certain patients with renal cell carcinoma (RCC). See safety data from KEYNOTE-564.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/adjuvant-renal-cell-carcinoma","@type":"ApprovedIndication","name":"KEYTRUDA® ","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the adjuvant treatment of patients with renal cell carcinoma (RCC) at intermediate-high or high risk of recurrence following nephrectomy, or following nephrectomy and resection of metastatic lesions."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/adjuvant-renal-cell-carcinoma","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const arccSafetyAlt = "Adverse Reactions Occurring in ≥10% of Patients Receiving KEYTRUDA® (pembrolizumab) in KEYNOTE-564"

//from /src/util/data/indications.js
const indicationId = 29;
const jobCode = jobCodes[8].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Safety Data",
            items: [
                { text: 'Advanced RCC', url: '/safety/adverse-reactions/advanced-renal-cell-carcinoma' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Safety Data from KEYNOTE&#8288;-&#8288;564' }
]

const arccSafetyFootnotes = [
    {
        label: "c.",
        text: "Adverse reactions occurring at same or higher incidence than in placebo arm."
    },
    {
        label: "d.",
        text: "Graded per NCI-CTCAE v4.0."
    },
    {
        label: "e.",
        text: "Includes arthralgia, back pain, myalgia, arthritis, pain in extremity, neck pain, musculoskeletal pain, musculoskeletal stiffness, spinal pain, musculeskeletal chest pain, bone pain, musculoskeletal discomfort."
    },
    {
        label: "f.",
        text: "Includes asthenia, fatigue."
    },
    {
        label: "g.",
        text: "Includes rash, rash maculo-papular, rash papular, skin exfoliation, lichen planus, rash erythematous, eczema, rash macular, dermatitis acneiform, dermatitis, rash pruritic, Stevens-Johnson syndrome, eczema asteatotic, palmar-plantar erythrodysaesthesia syndrome."
    },
    {
        label: "h.",
        text: "Includes diarrhea, colitis, enterocolitis, frequent bowel movements, enteritis."
    },
    {
        label: "i.",
        text: "Includes abdominal pain, abdominal pain lower, abdominal pain upper, abdominal discomfort, gastrointestinal pain."
    },
    {
        label: "j.",
        text: "Includes upper-airway cough syndrome, productive cough, cough."
    },
    {
        label: "k.",
        text: "Includes tension headache, headache, sinus headache, migraine with aura."
    },
    {
        label: "l.",
        text: "Includes alanine aminotransferase increased, aspartate aminotransferase increased, blood bilirubin increased, drug-induced liver injury, hepatic enzyme increased, hepatic function abnormal, hepatocellular injury, hepatotoxicity, hyperbilirubinemia, immune-mediated hepatitis, liver function test increased, transaminases increased, gamma-glutamyl transferase increased, bilirubin conjugated increased."
    },
    {
        label: "m.",
        text: "Includes acute kidney injury, blood creatinine increased, renal failure, renal impairment, oliguria, glomerular filtration rate decreased, nephropathy toxic."
    },
    
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label} >
                            <DataTable
                                title='Selected<sup>c</sup> Adverse Reactions Occurring in ≥10% of Patients Receiving KEYTRUDA'
                                mobileImage={() => <img src={rccSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={arccSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/RCC-Adjuvant-564-safety-large-V5.png" alt={arccSafetyAlt}/>}
                                footnotes={arccSafetyFootnotes} 
                                definitions="ALT = alanine aminotransferase; NCI-CTCAE = National Cancer Institute-Common Terminology Criteria for Adverse Events."
                                limitation="Serious adverse reactions occurred in 20% of these patients receiving KEYTRUDA. Serious adverse reactions (≥1%) were acute kidney injury, adrenal insufficiency, pneumonia, colitis, and diabetic ketoacidosis (1% each). Fatal adverse reactions occurred in 0.2% of those treated with KEYTRUDA, including 1 case of pneumonia."
                                additionalInformation={[
                                    {
                                        text: `
                                                <p>The median duration of exposure to KEYTRUDA was 11.1 months (range: 1 day to 14.3 months).</p>
                                                <p>21% of patients discontinued treatment with KEYTRUDA due to adverse reactions. The most common (≥1%) were increased ALT (1.6%), colitis (1%), and adrenal insufficiency (1%).</p>
                                            `
                                    },
                                ]}
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
